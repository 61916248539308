import * as React from 'react';
import {Box, Button, Checkbox, Divider, FormHelperText, Grid, Link, Paper, TextField, Typography} from "@mui/material";
import {useState} from "react";
import axios from 'axios'
import {useSnackbar} from "notistack";
import {Title} from "../../utils/Title";
import {EMAIL_REGEX, PASSWORD_REGEX} from "../../utils/const";
import {getErrorMessage, notifyUser} from "../../utils/notificationCenter";
import MenuItem from "@mui/material/MenuItem";
import Select, {SelectChangeEvent} from "@mui/material/Select";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL!
const CREATE_ACCOUNT_ROUTE = process.env.REACT_APP_CREATE_ACCOUNT_ROUTE!
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL!
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL!

const iso3311a2 = require('iso-3166-1-alpha-2');

export const CreateAccountBoard: React.FC = () => {
    const [email, setMail] = useState<string>("");
    const [pwd, setPwd] = useState<string>("");

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [country, setCountry] = useState<string>("");

    const disabledCountries = [
        "AF",
        "BY",
        "CF",
        "KP",
        "IQ",
        "IR",
        "LB",
        "SO",
        "SS",
        "SD",
        "SY",
        "UA",
        "YE",
        "ZW",
        "LY",
        "RU",
        "CD",
        "MM"
    ];
    const items: JSX.Element[] = iso3311a2.getCountries().reduce((results: JSX.Element[], value: any, index: any) => {
        if (!disabledCountries.includes(iso3311a2.getCode(value))) {
            results.push(<MenuItem key={index} value={iso3311a2.getCode(value)}>{value}</MenuItem>)
        }
        return results
    }, []);

    const [checked, setChecked] = useState<boolean>(false);
    const [validEmail, setValidMail] = useState<string>("");
    const [validPwd, setValidPwd] = useState<string>("");

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleClick = () => {
        axios.post(BACKEND_URL + CREATE_ACCOUNT_ROUTE, {
            email: email,
            password: pwd,
            firstName: firstName,
            lastName: lastName,
            country: country
        }).then(() => {
            notifyUser("Account successfully created, activate it using the link in the mail sent to your address.", false, enqueueSnackbar, closeSnackbar)
        }).catch(response => {
            setMail("")
            setValidMail("")
            setPwd("")
            setValidPwd("")
            notifyUser("Error during account creation : " + getErrorMessage(response), true, enqueueSnackbar, closeSnackbar)
        })
    }

    console.log(!EMAIL_REGEX.test(email))
    console.log(email !== "")
    console.log(!PASSWORD_REGEX.test(pwd))
    console.log(pwd !== "")

    return (
        <>
            <Title text={"Dynalips - Account Creation"}/>
            <Box sx={{m: 4, flexGrow: 1, display: 'flex'}} justifyContent="center" alignItems="center" textAlign="center">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"} align={"center"}>Dynalips - Create your account</Typography>
                        <Divider orientation="horizontal" variant="middle" flexItem sx={{marginTop: 1}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} align={"center"}>Login infos</Typography>
                    </Grid>
                    <Grid item xs={0} md={1}/>
                    <Grid item xs={12} md={5}>
                        <Typography>Email</Typography>
                        <Paper variant={"outlined"} sx={{marginBottom: 1}}>
                            <TextField id={"email"} type={"email"} value={email}
                                error={!EMAIL_REGEX.test(email) || email === ""}
                                helperText={(!EMAIL_REGEX.test(email) || email === "") ? "Must be a valid email." : ""}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setMail(event.target.value);
                                }}
                                fullWidth placeholder={"Email address"}/>
                        </Paper>
                        <Paper variant={"outlined"}>
                            <TextField id={"valid-email"} type={"email"} value={validEmail} error={email !== validEmail}
                                       helperText={(email !== validEmail) ? "Emails are not similar." : ""}
                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                           setValidMail(event.target.value);
                                       }}
                                       fullWidth placeholder={"Enter your email again"}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography>Password</Typography>
                        <Paper variant={"outlined"} sx={{marginBottom: 1}}>
                            <TextField type={"password"} value={pwd}
                                       error={!PASSWORD_REGEX.test(pwd) || pwd === ""}
                                       helperText={(!PASSWORD_REGEX.test(pwd) || pwd === "") ? "Password must be 8 characters min and have letters and numbers." : ""}
                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                           setPwd(event.target.value);
                                       }}
                                       fullWidth placeholder={"Password"}/>
                        </Paper>
                        <Paper variant={"outlined"}>
                            <TextField type={"password"} value={validPwd} error={pwd !== validPwd}
                                       helperText={(pwd !== validPwd) ? "Passwords are not similar." : ""}
                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                           setValidPwd(event.target.value);
                                       }}
                                       fullWidth placeholder={"Enter your password again"}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={0} md={1}/>
                    <Grid item xs={12}>
                        <Divider orientation="horizontal" variant="middle" flexItem sx={{marginTop: 1, marginBottom: 1}}/>
                        <Typography variant={"h6"} align={"center"}>Personal infos</Typography>
                    </Grid>
                    <Grid item xs={0} md={1.5}/>
                    <Grid item xs={12} md={3}>
                        <Typography>First name</Typography>
                        <Paper variant={"outlined"}>
                            <TextField value={firstName} error={firstName === ""}
                                       helperText={(firstName === "") ? "Please provide your first name." : ""}
                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                           setFirstName(event.target.value);
                                       }}
                                       fullWidth placeholder={"Enter your first name"}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>Last name</Typography>
                        <Paper variant={"outlined"}>
                            <TextField value={lastName} error={lastName === ""}
                                       helperText={(lastName === "") ? "Please provide your last name." : ""}
                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                           setLastName(event.target.value);
                                       }}
                                       fullWidth placeholder={"Enter your last name"}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>Country</Typography>
                        <Paper variant={"outlined"}>
                            <Select
                              id={"country-select"}
                              value={country}
                              error={country === ""}
                              onChange={(event: SelectChangeEvent) => {
                                  setCountry(event.target.value)
                              }}
                              sx={{width: "100%", height: "100%"}}
                              size="small"
                            >
                                {items}
                            </Select>
                            {
                                country === "" ? <FormHelperText error>Please provide your country.</FormHelperText> : ""
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={0} md={1.5}/>
                    <Grid item xs={12}>
                        <Divider orientation="horizontal" variant="middle" flexItem sx={{marginTop: 1, marginBottom: 1}}/>
                        <Box component={"div"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Typography sx={{textAlign: "center"}}>I have read and I accept the <Link href={"https://dynalips.com/terms-policies-cgu-cgv/"} color={"#931a95"}>Terms & Policies</Link>.</Typography>
                            <Checkbox color={"secondary"} checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}/>
                        </Box>
                        <Typography>Already have an account ? <Link href={FRONTEND_URL + "?redirect=" + REDIRECT_URL} color={"#931a95"}>Login here</Link></Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Button
                            disabled={firstName === "" || lastName === "" || country === "" || !checked || pwd !== validPwd || email !== validEmail || !email || !pwd || !EMAIL_REGEX.test(email) || !PASSWORD_REGEX.test(pwd)}
                            sx={{fontSize: "1em", marginTop: 1}}
                            type={"submit"} variant="contained"
                            onClick={handleClick}
                        >
                            <Typography sx={{textAlign: "center"}}>Create account</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}