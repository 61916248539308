import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {LoginBoard} from "./boards/login_board/LoginBoard";
import {CreateAccountBoard} from "./boards/create_account_board/CreateAccountBoard";
import {HeadBar} from "./HeadBar";
import {VerifyAccount} from "./boards/VerifyAccount";
import {ResetPasswordBoard} from "./boards/reset_password_board/ResetPasswordBoard";
import {ChangePasswordBoard} from "./boards/change_password_board/ChangePasswordBoard";
import {NotFoundBoard} from "./boards/not_found_board/NotFoundBoard";

function App() {
  return (
      <SnackbarProvider>
          <HeadBar/>
          <BrowserRouter>
              <Routes>
                  <Route path="/login/" element={<LoginBoard/> } />
                  <Route path="/login/verifyAccount" element={<VerifyAccount/> } />
                  <Route path="/login/create" element={<CreateAccountBoard/> } />
                  <Route path="/login/resetPwd" element={<ResetPasswordBoard/> } />
                  <Route path="/login/changePwd" element={<ChangePasswordBoard/> } />
                  <Route path="/*" element={<NotFoundBoard />} />
              </Routes>
          </BrowserRouter>
      </SnackbarProvider>
  );
}

export default App;
