import * as React from 'react';
import {Box, Button, Link, Paper, TextField, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import {useSnackbar} from "notistack";
import {notifyUser} from "../../utils/notificationCenter";
import {Title} from "../../utils/Title";
import {EMAIL_REGEX} from "../../utils/const";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL!
const CHECK_REDIRECT_ROUTE = process.env.REACT_APP_CHECK_REDIRECT_ROUTE!
const LOGIN_ROUTE = process.env.REACT_APP_LOGIN_ROUTE!

export const LoginBoard: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [pwd, setPwd] = useState<string>("");
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const checkRedirect = async (url: string) => {
        return axios.post(BACKEND_URL + CHECK_REDIRECT_ROUTE, {
            "url": url
        })
            .then(_ => true)
            .catch(__ => false)
    }

    const handleLogin = async () => {
        const redirect_url = searchParams.get('redirect')
        if (!redirect_url) {
            notifyUser("Missing redirect URL.", true, enqueueSnackbar, closeSnackbar);
        } else {
            if (await checkRedirect(redirect_url)) {
                axios.post(BACKEND_URL + LOGIN_ROUTE, {
                    email: email,
                    password: pwd,
                }).then(async (res) => {
                    const jwt = res.data.jwt
                    window.location.href = redirect_url + "?jwt=" + jwt
                    notifyUser("Connection done. You will be automatically redirected.", false, enqueueSnackbar, closeSnackbar)
                }).catch(response => {
                    setEmail("")
                    setPwd("")
                    enqueueSnackbar(response.response.data.message, { autoHideDuration: 2000, variant: "error" })
                })
            } else {
                notifyUser("Incorrect redirect URL.", true, enqueueSnackbar, closeSnackbar);
            }
        }
    }

    return (
        <>
            <Title text={"Dynalips - Id Provider"}/>
            <Box component={"div"} justifyContent={"center"} display={"flex"} flexDirection={"column"} sx={{
                maxWidth: "330px",
                padding: "15px",
                margin: "auto",
                minHeight: "700px"
            }}>
                <Typography variant={"h5"} align={"center"} sx={{marginBottom: 1}}>Dynalips - Login</Typography>
                <Paper variant={"outlined"}>
                    <TextField type={"email"} value={email} error={!EMAIL_REGEX.test(email) && email !== ""}
                               helperText={(!EMAIL_REGEX.test(email) && email !== "") ? "Must be a valid email." : ""}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setEmail(event.target.value);
                               }}
                               fullWidth placeholder={"Email address"}/>
                </Paper>
                <Paper variant={"outlined"} sx={{
                    marginTop: "15px",
                    marginBottom: "15px"
                }}>
                    <TextField type={"password"} value={pwd}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setPwd(event.target.value);
                               }}
                               fullWidth placeholder={"Password"}/>
                </Paper>
                <Typography>Don't have an account ? <Link href={"/login/create"} color={"#931a95"}>Create one !</Link></Typography>
                <Typography>Forgot your password ? <Link href={"/login/resetPwd"} color={"#931a95"}>Reset it here</Link></Typography>
                <Button sx={{fontSize: "1em"}} type={"submit"} onClick={handleLogin}
                        disabled={pwd === "" || email === "" || !EMAIL_REGEX.test(email)}
                        variant="contained">
                    <Typography sx={{textAlign: "center"}}>Login</Typography>
                </Button>
            </Box>
        </>
    );
}