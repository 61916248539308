import React from 'react';
import {Box, Typography} from "@mui/material";
import {Title} from "../../utils/Title";

export const NotFoundBoard: React.FC = () => {
    return (
      <>
        <Title text={"Dynalips - Id Provider"}/>
        <Box sx={{margin: 2}}>
            <Typography>This page does not exist.</Typography>
        </Box>
      </>
    )
}
