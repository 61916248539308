import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from './res/DYNALIPS_LOGO_COLOR.png';
import {Link} from "@mui/material";

export const HeadBar: React.FC = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary" enableColorOnDark sx={{
                position: 'relative',
            }}>
                <Toolbar sx={{height:60}}>
                    <Link href={"https://dynalips.com/"}>
                        <Box component="img" sx={{height: 54}} alt="Dynalips logo" src={logo}/>
                    </Link>
                    <Box sx={{left:0, position: "absolute", width: 1, display:"flex", justifyContent:"center"}}/>
                </Toolbar>
            </AppBar>
        </Box>
    );
}