import * as React from 'react';
import {Box, Button, Paper, TextField, Typography} from "@mui/material";
import {useState} from "react";
import axios from 'axios'
import {useSnackbar} from "notistack";
import {Title} from "../../utils/Title";
import {getErrorMessage, notifyUser} from "../../utils/notificationCenter";
import {useLocation} from "react-router-dom";
import {PASSWORD_REGEX} from "../../utils/const";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL!
const CHANGE_PWD_ROUTE = process.env.REACT_APP_CHANGE_PWD_ROUTE!
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL!
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL!


export const ChangePasswordBoard: React.FC = () => {
    const [pwd, setPwd] = useState<string>("");
    const [validPwd, setValidPwd] = useState<string>("");

    const search = useLocation().search
    const otp = new URLSearchParams(search).get('token');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const handleClick = () => {
        axios.post(BACKEND_URL + CHANGE_PWD_ROUTE, {
            token: otp,
            pwd: pwd
        }).then(() => {
            notifyUser("Password successfully changed, you will be redirect to the login page.", false, enqueueSnackbar, closeSnackbar)
            window.location.href = FRONTEND_URL + "?redirect=" + REDIRECT_URL;
        }).catch(response => {
            setPwd("")
            setValidPwd("")
            notifyUser("Error when changing password : " + getErrorMessage(response), true, enqueueSnackbar, closeSnackbar)
        })
    }

    return (
        <>
            <Title text={"Dynalips - Request password reset"}/>
            <Box component={"div"} justifyContent={"center"} display={"flex"} flexDirection={"column"} sx={{
                maxWidth: "330px",
                padding: "15px",
                margin: "auto",
                minHeight: "700px"
            }}>
                <Typography variant={"h5"} align={"center"} sx={{marginBottom: 1}}>Dynalips - Reset your password</Typography>
                <Paper variant={"outlined"} sx={{
                    marginTop: "15px",
                }}>
                    <TextField type={"password"} value={pwd}
                               error={!PASSWORD_REGEX.test(pwd) && pwd !== ""}
                               helperText={(!PASSWORD_REGEX.test(pwd) && pwd !== "") ? "Password must be 8 characters min and have letters and numbers." : ""}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setPwd(event.target.value);
                               }}
                               fullWidth placeholder={"Password"}/>
                </Paper>
                <Paper variant={"outlined"} sx={{
                    marginBottom: "15px"
                }}>
                    <TextField type={"password"} value={validPwd} error={pwd !== validPwd}
                               helperText={(pwd !== validPwd) ? "Passwords are not similar." : ""}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setValidPwd(event.target.value);
                               }}
                               fullWidth placeholder={"Enter your password again"}/>
                </Paper>
                <Button
                    disabled={pwd !== validPwd  || !pwd}
                    sx={{fontSize: "1em", marginTop: 1}}
                    type={"submit"} variant="contained"
                    onClick={handleClick}
                >
                    <Typography sx={{textAlign: "center"}}>Change your password</Typography>
                </Button>
            </Box>
        </>
    );
}