import {FC} from "react";
import {useLocation} from "react-router-dom";
import {getErrorMessage, notifyUser} from "../utils/notificationCenter";
import {useSnackbar} from "notistack";
import {Title} from "../utils/Title";
import * as React from "react";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL!
const ACTIVATE_ACCOUNT_ROUTE = process.env.REACT_APP_ACTIVATE_ACCOUNT_ROUTE!
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL!
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL!

export const VerifyAccount: FC = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const search = useLocation().search
    const otp = new URLSearchParams(search).get('token');
    axios.post(BACKEND_URL + ACTIVATE_ACCOUNT_ROUTE, {
        "token": otp
    })
      .then(() => {
          window.location.href = FRONTEND_URL + "?redirect=" + REDIRECT_URL;
      })
      .catch((err) => {
          notifyUser("The verification of your account failed:" + getErrorMessage(err), true, enqueueSnackbar, closeSnackbar)
      })
    return <>
        <Title text={"Dynalips - Account verification"}/>
    </>
}
