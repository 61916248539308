import * as React from 'react';
import {Box, Button, Paper, TextField, Typography} from "@mui/material";
import {useState} from "react";
import axios from 'axios'
import {useSnackbar} from "notistack";
import {Title} from "../../utils/Title";
import {EMAIL_REGEX} from "../../utils/const";
import {getErrorMessage, notifyUser} from "../../utils/notificationCenter";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL!
const RESET_PWD_ROUTE = process.env.REACT_APP_RESET_PWD_ROUTE!

export const ResetPasswordBoard: React.FC = () => {
    const [email, setMail] = useState<string>("");

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const handleClick = () => {
        axios.post(BACKEND_URL + RESET_PWD_ROUTE, {
            email: email,
        }).then(() => {
            notifyUser("Please follow instructions in the mail that has bent sent to reset your password.", false, enqueueSnackbar, closeSnackbar)
        }).catch(response => {
            setMail("")
            notifyUser("Error when requesting password reset : " + getErrorMessage(response), true, enqueueSnackbar, closeSnackbar)
        })
    }

    return (
        <>
            <Title text={"Dynalips - Request password reset"}/>
            <Box component={"div"} justifyContent={"center"} display={"flex"} flexDirection={"column"} sx={{
                maxWidth: "330px",
                padding: "15px",
                margin: "auto",
                minHeight: "700px"
            }}>
                <Typography variant={"h5"} align={"center"} sx={{marginBottom: 1}}>Dynalips - Reset your password</Typography>
                <Paper variant={"outlined"}>
                    <TextField id={"email"} type={"email"} value={email}
                        error={!EMAIL_REGEX.test(email) && email !== ""}
                        helperText={(!EMAIL_REGEX.test(email) && email !== "") ? "Must be a valid email." : ""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setMail(event.target.value);
                        }}
                        fullWidth placeholder={"Email address"}/>
                </Paper>
                <Button
                    disabled={!email || !EMAIL_REGEX.test(email)}
                    sx={{fontSize: "1em", marginTop: 1}}
                    type={"submit"} variant="contained"
                    onClick={handleClick}
                >
                    <Typography sx={{textAlign: "center"}}>Request password reset</Typography>
                </Button>
            </Box>
        </>
    );
}