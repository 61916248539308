import {FC, useEffect} from "react";

interface TitleProps {
    text: string;
}

export const Title: FC<TitleProps> = (prop: TitleProps) => {
    useEffect(() => {
        document.title = prop.text
    }, []);

    return <title>={prop.text}</title>
}